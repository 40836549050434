#span {
    display: inline-block;
    background-color: slategray;
  }
  
  .loading-container {
    height: 2rem;
    width: 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .loading-dot {
    height: 0.4rem;
    width: 0.4rem;
    border-radius: 50%;
    animation: spin 5000ms ease-out infinite;
    transform-origin: 250% 0.3rem;
    perspective: 700px;
    perspective-origin: top center;
    box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.1);
    position: absolute;
  }
  
  @keyframes fade {
    0% {
      opacity: 0.3;
      transform: translateY(0);
    }
    60% {
      opacity: 1;
      transform: translateY(-1rem);
    }
    100% {
      opacity: 0.3;
      transform: translateY(0);
    }
  }
  
  @keyframes fade-in {
    to {
      opacity: 0.3;
    }
  }
  
  @keyframes spin {
    30% {
      transform: unset;
    }
    31% {
      transform: translateX(0);
    }
    32% {
      transform: rotateZ(-1440deg);
    }
    80% {
      transform: unset;
    }
  }
  
  .spinner {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 1rem;
    width: 180px;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.5rem;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  }
  