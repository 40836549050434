.toastContainer {
    background-color: white;
    border: 1px solid #073763;
    border-radius: 15px;
    color: black;
    margin-right: 10px;
    overflow: block;
    justify-content: center;
    justify-items: center;
    padding: 0px;
    box-shadow: 3px 3px #073763;
    overflow: hidden;
  }
  .toastBody {
    background-color: #dec2ed;
    color: black;
  }
  .toastClass {
    background-color: white;
  }