.tree-node {
    margin-left: 20px;
  }
  
  .node-label {
    cursor: pointer;
    display: inline-block;
    padding: 5px;
  }
  
  .toggle-icon {
    margin-right: 5px;
  }
  
  .open {
    font-weight: bold;
  }
  
  .node-children {
    margin-left: 20px;
    border-left: 1px dotted #333;
    border-bottom: 1px dotted #333;
    padding: 10px;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .node-children > div {
    animation: fadeIn 1s ease;
  }