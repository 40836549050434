/* ///classes to change style in menuList////// */



/* ///icon size when drawer open */
/* .ant-menu .ant-menu-item .ant-menu-item-icon,
.ant-menu .ant-menu-submenu-title .ant-menu-item-icon {
  height: 20px;
} */

/* ///icon size when drawer close */
/* :where(.css-dev-only-do-not-override-26rdvq).ant-menu-inline-collapsed >.ant-menu-item, :where(.css-dev-only-do-not-override-26rdvq).ant-menu-inline-collapsed >.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item, :where(.css-dev-only-do-not-override-26rdvq).ant-menu-inline-collapsed >.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title, :where(.css-dev-only-do-not-override-26rdvq).ant-menu-inline-collapsed >.ant-menu-submenu>.ant-menu-submenu-title {
  inset-inline-start: 0;
  padding-inline: calc(41% - 12px);
  text-overflow: clip;
} */

/* .ant-menu-submenu-popup {
  top: 100px !important;
  height: 10px !important;
} */

/* .ant-menu-item-selected {
  background-color: #1e3a8a !important;
  border-left: 5px solid #ff4d4f;
  color: #ffffff !important;
} */

/* .ant-dropdown-menu {
  max-height: 250px;
  overflow-y: scroll;
} */

/* ///////////////////////// */
/*Drawer Sidebar Scrollbar Style  */
.MuiDrawer-paper::-webkit-scrollbar {
  width: 7px !important;
}

.MuiDrawer-paper::-webkit-scrollbar-track {
  background: white !important;
}

.MuiDrawer-paper::-webkit-scrollbar-thumb {
  background-color: #1e3a8a;
  border-radius: 80px;
  border: 1px solid #a3a5a5;
  cursor: pointer;
}

/* //////////////////////////// */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);

  color: white;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


